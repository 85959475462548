<template>
    <div class="mainID-cpn">
        <div class="FaceID">
            <img src="@/assets/about/imPlume.png" class="helloImPlume"/>
        </div>
        <div class="data">
            <div class="info">
                <p>Name: undefined</p>
                <p>Surname: Julien</p>
                <p>Nickname: plume</p>
                <p>npm init: 16 02 1988</p>
                <p>current buffer position: France, EU</p>
            </div>
            <div class="contact blink" @click="showContact = true">
            <transition>
            <div class="contact-mail" v-if='showContact'>
                {{contact}}
            </div>
            <div v-else class="contact-btn-wording"> Press to CONTACT</div>
            </transition>
            </div>
        </div>
    </div>
</template>

<script>
// import Contact from '@/components/About/Contact'
export default {
    data() {
        return {
            showContact: false,
            contact: 'hello@imPlume.com'
        }
    }
}
</script>

<style lang="scss">



    .mainID-cpn{
        width: 90%;
        margin: 0 auto;
        margin-top: 2rem;
        // border: 1px solid white;
        text-align: start;
        font-size: .5rem;
        display: flex;
        justify-content: center;
        // justify-content: space-between;
        .FaceID{
        display: flex;
        max-width: 20%;
        justify-content: flex-end;
        object-fit: contain;
        margin-right: .2rem;
            .helloImPlume{
                // width: 100%;
                // min-height: 100%;
                right: 0;
            }
        }
        .data{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .info{
                height: 60%;
                font-size: .5rem;
                display: flex;
            flex-direction: column;
                // align-items: center;
                justify-content: space-between;
            }
            .contact{
                // font-size: .8rem;
                border: 1px solid white;
                color: white;
                background: green;
                height: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
</style>