<template>
    <div>
           <div class="historique">
      <table class="table_cv">
        <caption>SELECTED EXPERIENCES</caption>
        <tr class="table_title">
          <th>Year</th>
          <th></th>
          <th>desc.</th>
        </tr>
        <tr v-for="(xp, index) in datas" :key="index"
        class="tr_cv highlight" @click="linkToPortfolio(xp.link)">
          <td class="td_cv"><p>{{xp.year}}</p>
          </td>
          <td class="td_cv centerTxt"><p>{{xp.where}}</p>
          </td>
          <td class="td_cv"><p>{{ xp.desc }}</p></td>
        </tr>
        <!-- <tr class="tr_cv">
          <td class="td_cv">2017
          </td>
          <td class="td_cv centerTxt">_
          </td>
          <td class="td_cv">video technical for arts performances.
          </td>
        </tr>
        <tr class="tr_cv">
          <td class="td_cv">2014
          </td>
          <td class="td_cv centerTxt" rowspan="2"> univ-tours
          </td>
          <td class="td_cv">Computational archaeology (master degree)
          </td>
        </tr>
        <tr class="tr_cv">
          <td class="td_cv">2013
          </td>
          <td class="td_cv"> archaeology studies (bachelor degree)
          </td>
        </tr> -->
      </table>
    </div>
    </div>
</template>

<script>
export default {
    name: 'ImPlumeWebsiteV2BestExp',

    data() {
        return {
            datas: [{
                year: '2018 - current',
                where: 'BWEEZ',
                desc: 'front-end developper',
                link: 'BWEEZ'
            },
        {
            year: '2017',
            where: '-',
            desc: 'video technical for art perfomances',
            link: 'osci'
        },
    {
        year: '2014',
        where: 'univ-tours',
        desc: 'Computational archaeology (master degree) ',
        link: undefined
    }]
        };
    },

    mounted() {
        
    },

    methods: {
        linkToPortfolio(link){
            if(link){
                this.$router.push({name: link})
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.highlight:hover{
    transition-duration: .5s;
    color: white;
    background:blue;
}
.historique{
    margin: 0 auto;
    width: 90%;
    height: 100%;
    margin-top: 2rem;
}
.table_cv{
    font-size: .5rem;
  color: blue;
  text-shadow:1px 1px 1px rgb(132, 132, 132);
  border-collapse: collapse;
}
.td_cv, .table_title{
  border: 2px dotted white;
}
.td_cv{
    height: 100%;
    height: 1.5rem;
    p{
        min-height: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.tr_cv{
  margin: 1rem;
  padding: 1rem;;
  height: 1.5rem;

}
.table_title{
  color: white;
}
.centerTxt{
text-align: center;
margin: 0 auto;}
</style>