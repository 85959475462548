<template>
    <div class="about-view glowing-green-light fw">
        <div class="glowing-green">About</div>
        
<MainID class="fw" style="max-height: 20%;"/>
<div class="BestExp-centering">
<BestExp class="fw" style="margin-bottom:2rem"/>
<Workflow/>
<div class="message-to-the-truth">
my full life is accessible to my fellow devs through the <a class="glowing">console</a> ;)
</div>

</div>

    </div>
</template>

<script>
import BestExp from '@/components/About/BestExp'
import MainID from '@/components/About/MainID'
import Workflow from '@/components/About/Workflow'
import consoleScript from '@/components/Interface/console/consoleScript.js';
export default {
    name: 'ImPlumeWebsiteV2About',
    components: {
        MainID,
        BestExp,
        Workflow
    },
    data() {
        return {
            
        };
    },

    mounted() {
        console.clear()
        consoleScript.header()
        // consoleScript.menu('about')
        consoleScript.timeline()
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
.BestExp-centering{
    width:90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.about-view{height: 90%;
    width: 100%;
    overflow: scroll}
    .message-to-the-truth{
        color: yellow;
        line-height: 1rem;
        padding-left: 3rem;
        padding-right: 3rem;
        font-size: 0.7rem;
        margin-top: 0rem;
        margin-bottom: 3rem;
    }
    .glowing{
              color: yellow;
  text-shadow: 0 0 2pt #ffffff, 0 0 5pt #ffffff2f, 0 0 7pt #ffffff2f, 0 0 10pt #4aff182f, 0 0 10pt #49FF182f, 0 0 10pt #49FF182f, 0 0 17pt #4aff182f, 0 0 18pt #4aff182f;

    }
    .glowing-green{
  color: green;
  text-shadow: 0 0 2pt #ce6b00, 0 0 5pt #ffffff2f, 0 0 7pt #ffffff2f, 0 0 10pt #4aff182f;

}
    .glowing-green-light{
  color: green;
  text-shadow: 0 0 1pt #ce6b00, 0 0 3pt #4aff182f;

}
</style>